import { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [data, setData] = useState([])
  const [expanded, setExpanded] = useState(new Set())

  useEffect(()=>{
    fetch("https://canopy-frontend-task.vercel.app/api/holdings")
    .then((result)=>{
      result.json()
      .then((resp)=>{
        setData(resp.payload)
      })
    })
  },[])

  const DataExpand = (assetClass) => {
    if (expanded.has(assetClass)) {
      expanded.delete(assetClass)
    } else {
      expanded.add(assetClass)
    }
    setExpanded(new Set(expanded))
  }

  return (
    <>
      {Object.entries(data.reduce((acc, item) => {
        if (!acc[item.asset_class]) {
          acc[item.asset_class] = []
        }
        acc[item.asset_class].push(item)
        return acc
      }, {})).map(([assetKey, items]) => (
        <div key={assetKey}>
          <h5 onClick={() => DataExpand(assetKey)}>
            {assetKey} <span className="ml-2">({items.length})</span>
            <i className="fa fa-caret-down ml-2" aria-hidden="true"></i>
          </h5>
          {expanded.has(assetKey) && (
            <table className="table table-hover">
              <tbody>
                <tr>
                  <th>NAME OF THE HOLDING</th>
                  <th>TICKER</th>
                  <th>AVERAGE PRICE</th>
                  <th>MARKED PRICE</th>
                  <th>LATEST CHANGE PERCENTAGE</th>
                  <th>MARKED VALUE IN BASE CCY</th>
                </tr>
                {items.map((item, key)=>{
                  return(
                    <tr key={key}>
                    <td>{item.name}</td>
                    <td>{item.ticker}</td>
                    <td>{item.avg_price}</td>
                    <td>{item.market_price}</td>
                    <td>{item.latest_chg_pct}</td>
                    <td>{item.market_value_ccy}</td>
                  </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </div>
      ))}
    </>
  );
}

export default App;